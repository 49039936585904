import { useNavigation } from '@react-navigation/core';
import { HostsModel } from '@w3lcome/types';
import { Button, Text, Input, Header, HostsVisitsFlatList } from '_/components';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import KeyboardContainer from '_/components/KeyboardContainer';
import { colors, fontSizes } from '_/config/theme';
import { useCustomization } from '_/hooks/CustomizationContext';
import { useVisit } from '_/hooks/VisitContext';
import { useNextCheckinScreen } from '_/hooks/useNextCheckinScreen';
import { useCentralizedValuesFallback } from '_/hooks/useCentralizedValuesFallback';
import { AppRoute, HostProps } from '_/navigation/types';
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TextInput } from 'react-native';

const CheckinHostScreen: React.FC = () => {
  const { visit, setVisit, navigateToHome } = useVisit();
  const { t } = useTranslation();
  const navigation = useNavigation<HostProps>();
  const { customization } = useCustomization();

  const { selectedOrFallbackCustomization, searchSelectedHost, selectedHosts } =
    useCentralizedValuesFallback();

  const [textSearch, setTextSearch] = useState('');
  const inputSearchRef = useRef<TextInput>(null);
  const { goToNextScreen } = useNextCheckinScreen(AppRoute.CHECKIN_HOST);
  const [isFocusedInput, setIsFocusedInput] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      inputSearchRef?.current?.focus();
    }, 500);
    if (!selectedHosts.length) {
      searchSelectedHost();
    }

    // To reset hostsList when unmounting
    return () => searchSelectedHost();
  }, []);

  function onSelectHost(host: HostsModel) {
    setVisit({ hostId: host.id, host });
    handleNextScreen();
  }

  function handleNextAddedHost() {
    setVisit({ customHostName: textSearch });
    handleNextScreen();
  }

  function handleNextScreen() {
    goToNextScreen();
  }

  function handleGoBack() {
    if (
      visit.isAnonymous ||
      (selectedOrFallbackCustomization?.ipadHideVisitorInfoEnable && visit.isRecurrent)
    ) {
      navigateToHome(navigation.navigate);
    } else {
      navigation.navigate(AppRoute.CHECKIN_INFORMATION);
    }
  }

  function onChange(text: string) {
    searchSelectedHost(text);
    setTextSearch(text);
  }

  return (
    <KeyboardContainer
      keyboardOpen={isFocusedInput}
      onChange={onChange}
      inputName="search"
      keyboardType="default"
    >
      <View style={styles.container}>
        <ContainerWidthLimit>
          <Header onGoBack={handleGoBack} />

          <View style={styles.content}>
            <View style={styles.innerContent}>
              <View style={styles.headerText}>
                <Text center size={fontSizes.xl}>
                  {t('hostScreen.Greeting')}
                  <Text center fontType="bold" size={fontSizes.xl}>
                    {` ${visit.name || ''} `}
                  </Text>
                  {t('hostScreen.Message')}
                </Text>
              </View>

              <View style={styles.searchContent}>
                <Input
                  icon="search"
                  autoCapitalize="words"
                  label={t('hostScreen.Search')}
                  ref={inputSearchRef}
                  value={textSearch}
                  onChangeText={onChange}
                  onFocus={() => setIsFocusedInput(true)}
                  onBlur={() => setIsFocusedInput(false)}
                />
              </View>

              {(textSearch.length >= 3 ||
                (selectedOrFallbackCustomization?.hostDisplayAll &&
                  !selectedOrFallbackCustomization?.hideLastNameEnabled)) &&
                (selectedOrFallbackCustomization?.hideLastNameEnabled
                  ? selectedHosts.length <= 3
                  : selectedHosts.length > 0) &&
                selectedHosts.length !== 0 && (
                  <HostsVisitsFlatList
                    data={selectedHosts}
                    isHosts
                    onPressFunction={(host) => onSelectHost(host)}
                    flatListStyled={{ paddingBottom: isFocusedInput ? 0 : 48 }}
                  />
                )}

              {selectedHosts.length === 0 && textSearch !== '' && (
                <View style={styles.hostNotFound}>
                  <Text center size={fontSizes.xl2}>
                    {t('hostScreen.couldNotFindHost')}
                  </Text>
                  <Text center fontType="bold" size={fontSizes.xl3} style={styles.hostNotFoundName}>
                    {textSearch}
                  </Text>
                  {selectedOrFallbackCustomization?.hostVisitorCanAdd && (
                    <>
                      <Text center size={fontSizes.xl2}>
                        {t('hostScreen.writeHostFullName')}
                      </Text>
                      <Button
                        backgroundColor={customization?.mainColor || colors.primary}
                        labelColor={customization?.backgroundColor || colors.white}
                        rightIcon="chevron-right"
                        textStyles={{ fontSize: fontSizes.xl }}
                        buttonStyles={styles.hostNotFoundButton}
                        onPress={handleNextAddedHost}
                      >
                        {t('Next')}
                      </Button>
                    </>
                  )}
                </View>
              )}
            </View>
          </View>
        </ContainerWidthLimit>
      </View>
    </KeyboardContainer>
  );
};

export default CheckinHostScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    alignItems: 'center',
  },
  content: {
    flex: 1,
    alignItems: 'center',
  },
  innerContent: {
    flex: 1,
    width: '80%',
  },
  headerText: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  searchContent: {
    marginVertical: 32,
  },
  hostNotFound: {
    flex: 1,
    alignSelf: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  hostNotFoundButton: {
    marginTop: 24,
  },
  hostNotFoundName: {
    marginVertical: 12,
  },
});
