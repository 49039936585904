import { useNavigation } from '@react-navigation/native';
import { colors, fontSizes } from '_/config/theme';
import { getDateLocale } from '_/helpers/getDateLocale';
import { useAuth } from '_/hooks/AuthContext';
import { useVisit } from '_/hooks/VisitContext';
import { AppRoute, CheckinProps } from '_/navigation/types';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import uuid from 'react-native-uuid';

import { Text } from '..';
import ModalDoubleButton from '../ModalDoubleButton';

const CanceledModal: React.FC = () => {
  const { visit, canceledVisits, setVisit, navigateToHome } = useVisit();

  const { navigate } = useNavigation<CheckinProps>();
  const { t } = useTranslation();

  const { ipad } = useAuth();

  const formattedDate = (start: Date | undefined) => {
    if (start) {
      const date = new Date(start);

      const locale = getDateLocale(ipad?.currentLanguage);
      return format(date, 'Pp', { locale });
    }
    return '';
  };

  return (
    <ModalDoubleButton
      message={
        canceledVisits && canceledVisits?.length > 1
          ? t('checkinScreen.canceledMeetings')
          : t('checkinScreen.canceledMeeting')
      }
      messageBold={t('checkinScreen.newCheckin')}
      isModalVisible={visit.isCanceled ?? false}
      onCloseText={t('checkinScreen.Back')}
      onFinishText={t('Proceed')}
      onClose={() => {
        navigateToHome(navigate);
      }}
      onFinish={() => {
        setVisit({ isCanceled: false, id: uuid.v4().toString() });
        navigate(AppRoute.CHECKIN_INFORMATION);
      }}
    >
      <View style={styles.container}>
        <ScrollView>
          {canceledVisits?.map((e) => (
            <View style={styles.content}>
              <Text size={fontSizes.lg} fontType="medium" color={colors.dark3}>
                {e.meeting?.title}
              </Text>
              <Text fontType="bold" style={{ marginHorizontal: 8 }}>
                -
              </Text>
              <Text size={fontSizes.lg} fontType="medium" color={colors.dark3}>
                {formattedDate(e.meeting?.start)}
              </Text>
            </View>
          ))}
        </ScrollView>
      </View>
    </ModalDoubleButton>
  );
};

export default CanceledModal;

const styles = StyleSheet.create({
  container: {
    maxHeight: 200,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    alignItems: 'center',
    flexDirection: 'row',
  },
});
