import { useNavigation } from '@react-navigation/native';
import { useCustomization } from '_/hooks/CustomizationContext';
import { AppRoute, HomeProps } from '_/navigation/types';
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View, PanResponder, StyleSheet, Keyboard } from 'react-native';

import Modal from '../Modal';

import { useDetailedVisitFlowLogging } from '_/hooks/useDetailedVisitFlowLogging';
import { useVisit } from '_/hooks/VisitContext';
import { useChildrenCompanies } from '_/hooks/ChildrenCompaniesContex';
import { CompanyType } from '@w3lcome/types';
import { useCentralizedValuesFallback } from '_/hooks/useCentralizedValuesFallback';

interface InactivityProps {
  inactiveTimeMs?: number;
  onInactive?: () => void;
  children?: React.ReactNode;
}

const Inactivity: React.FC<InactivityProps> = ({
  inactiveTimeMs = 60000,
  onInactive,
  children,
}) => {
  const { t } = useTranslation();
  const [inactive, setInactive] = useState(false);
  const timerId = useRef<NodeJS.Timeout>();
  const timeoutIdInactive = useRef<NodeJS.Timeout>();
  const { customization, isVideoPlaying } = useCustomization();
  const [inactiveTime, setInactiveTime] = useState(inactiveTimeMs);
  const { navigateToHome } = useVisit();

  const { inactiveVisitLog } = useDetailedVisitFlowLogging();

  const navigation = useNavigation<HomeProps>();

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
      setInactiveTime(120000);
    });
    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
      setInactiveTime(60000);
    });

    return () => {
      keyboardDidHideListener.remove();
      keyboardDidShowListener.remove();
    };
  }, []);

  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponderCapture: () => {
        resetInactivityTimeout();

        return false;
      },
    })
  ).current;

  const resetInactivityTimeout = useCallback(() => {
    if (!inactiveTime || isVideoPlaying) {
      return;
    }

    if (customization?.disableScreenTimer) {
      return;
    }

    setInactive(false);
    clearTimeout(timerId.current as any);
    timerId.current = setTimeout(() => {
      Keyboard.dismiss();
      setInactive(true);
    }, inactiveTime);
  }, [inactiveTime, isVideoPlaying]);

  useEffect(() => {
    resetInactivityTimeout();

    return () => {
      clearTimeout(timerId.current as any);
    };
  }, [resetInactivityTimeout]);

  useEffect(() => {
    if (inactive) {
      timeoutIdInactive.current = setTimeout(() => {
        onInactive?.();
        setInactive(false);

        inactiveVisitLog();

        navigateToHome(navigation.navigate);
      }, 10000);
    }

    return () => {
      clearTimeout(timeoutIdInactive.current as any);
    };
  }, [inactive]);

  return (
    <View style={styles.container} {...panResponder.panHandlers}>
      <Modal
        title={t('inactivityComponent.question')}
        buttonText={t('Yes')}
        isVisible={inactive}
        onClose={resetInactivityTimeout}
        animationDurationMs={400}
      />
      {children}
    </View>
  );
};

export default Inactivity;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
