import { BadgeConfig, CompaniesModel, IpadPrintsModel, PrintersModel } from '@w3lcome/types';
import { Badge } from '_/components';
import { getBadge, getTestBadge } from '_/helpers/getBadge';
import { getBadgeConfig } from '_/helpers/getBadgeConfig';
import { pingPrinter, printImage } from '_/helpers/reactNativeBrother';
import { VisitsModelCheckin } from '_/interfaces/VisitsModelCheckin';
import { ipadLogsApi, ipadPrintsApi, printersApi, visitsApi } from '_/services/api';
import logger from '_/services/logger';
import axios from 'axios';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Platform } from 'react-native';

import { useAuth } from './AuthContext';
import { useCustomization } from './CustomizationContext';
import { useTranslation } from 'react-i18next';
import appConfig from '_/config/app';
import { Companies } from '_/constants/companies';
import { useChildrenCompanies } from './ChildrenCompaniesContex';

interface PrintersContextData {
  printVisitBadge: (visit: Partial<VisitsModelCheckin>) => Promise<void>;
  printSampleBadge: () => void;
  checkPrinter: () => Promise<void>;
  printer: PrintersModel | undefined;
  printerConnected: boolean | undefined;
}

const PrintersContext = createContext<PrintersContextData>({} as PrintersContextData);

type PrintersType = {
  children: React.ReactNode;
};

export const PrintersProvider: React.FC<PrintersType> = ({ children }) => {
  const { ipad, feathersApp } = useAuth();
  const { company } = useCustomization();
  const { selectedCompany } = useChildrenCompanies();
  const { t: translateFunction } = useTranslation();

  const [printer, setPrinter] = useState<PrintersModel>();
  const [printerConnected, setPrinterConnected] = useState<boolean>();
  const [currentBadge, setCurrentBadge] = useState<BadgeConfig>();

  const getPrinter = useCallback(async (printerId?: string | null) => {
    if (!printerId) {
      setPrinter(undefined);
      return;
    }

    try {
      const printer = await printersApi.getItem(printerId);
      setPrinter(printer);
    } catch (error) {
      logger(error);
    }
  }, []);

  useEffect(() => {
    getPrinter(ipad?.printerId);
  }, [getPrinter, ipad?.printerId]);

  const checkPrinter = useCallback(async () => {
    if (!printer) return;

    if (appConfig.company === Companies.INVENZI) {
      setPrinterConnected(true);
      return;
    }

    try {
      await pingPrinter(printer.ip);
      setPrinterConnected(true);
    } catch (error) {
      setPrinterConnected(false);
    }
  }, [printer]);

  useEffect(() => {
    if (!printer) {
      return;
    }

    checkPrinter();

    const intervalId = setInterval(() => {
      checkPrinter();
    }, 30_000);

    return () => {
      clearInterval(intervalId);
    };
  }, [printer, checkPrinter]);

  const customPrinterPrintBadge = useCallback(
    async (visit?: Partial<VisitsModelCheckin>) => {
      try {
        const url = printer?.ip.includes('http') ? printer.ip : `http://${printer!.ip}`;
        await axios.post(
          url,
          { visit, printer },
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
            },
          }
        );
      } catch (error) {
        logger(error);
        ipadLogsApi.insert({
          type: '[customPrinterPrintBadge]Printer error',
          message: error.message || 'Custom Printer Error',
        });
      }
    },
    [printer]
  );

  const printVisitBadge = useCallback(
    async (visit: Partial<VisitsModelCheckin>) => {
      if (!printer) {
        ipadLogsApi.insert({
          type: '[printVisitBadge]Printer error',
          message: 'No printer selected',
        });
        return;
      }

      if (printer.isCustomPrinter) {
        return customPrinterPrintBadge(visit);
      }

      if (Platform.OS !== 'ios') {
        ipadLogsApi.insert({
          type: '[printVisitBadge]Printer error',
          message: 'Printer only works on iOS',
        });
        return;
      }

      const badge = await getBadge(
        { ...visit, company: (selectedCompany || company) as CompaniesModel },
        printer,
        company?.lang,
        company?.pictureUrl,
        translateFunction
      );

      if (!badge) {
        ipadLogsApi.insert({
          type: '[printVisitBadge]Printer error',
          message: `Badge not supported yet (badge: ${printer.paper})`,
        });
        return;
      }

      setCurrentBadge(badge);
    },
    [printer, company, customPrinterPrintBadge]
  );

  const printSampleBadge = useCallback(async () => {
    if (!printer) {
      ipadLogsApi.insert({ type: 'Printer error', message: 'No printer selected' });
      return;
    }

    if (printer.isCustomPrinter) {
      return customPrinterPrintBadge();
    }

    if (Platform.OS !== 'ios') {
      ipadLogsApi.insert({
        type: 'Printer error',
        message: 'Printer only works on iOS',
      });
      return;
    }

    const badge = getTestBadge(printer);

    if (!badge) {
      ipadLogsApi.insert({
        type: 'Printer error',
        message: `Badge not supported yet (badge: ${printer.paper})`,
      });
      return;
    }

    setCurrentBadge(badge);
  }, [printer, customPrinterPrintBadge]);

  const handlePrintBadge = useCallback(
    async (rawUri: string) => {
      if (!printer) {
        ipadLogsApi.insert({ type: '[handlePrintBadge]Printer error', message: 'Missing printer' });
        return;
      }

      if (!printerConnected) {
        ipadLogsApi.insert({
          type: '[handlePrintBadge]Printer error',
          message: 'Printer is not connected',
        });
        return;
      }

      const { labelSize, rotate } = getBadgeConfig(printer.paper);

      const { uri } = await manipulateAsync(rawUri, [{ rotate }], {
        compress: 1,
        format: SaveFormat.PNG,
      });

      try {
        await printImage({ ipAddress: printer.ip, modelName: printer.type }, uri, {
          autoCut: true,
          labelSize,
          isHalftoneErrorDiffusion: true,
          isHighQuality: true,
        }).catch((error: any) => {
          logger(error);
          ipadLogsApi.insert({
            type: '[handlePrintBadge]Printer error',
            message: 'Wrong printer label',
          });
        });
      } catch (error) {
        logger(error);
        ipadLogsApi.insert({
          type: '[handlePrintBadge]Printer error',
          message: 'Wrong printer label',
        });
      }

      setCurrentBadge(undefined);
    },
    [printer, printerConnected]
  );

  const socketPrint = useCallback(
    async (data: IpadPrintsModel) => {
      try {
        if (data.ipadId === ipad?.id) {
          if (data.visitId) {
            const visit = (await visitsApi.getItem(data.visitId)) as Partial<VisitsModelCheckin>;
            await printVisitBadge(visit);
          } else {
            await printSampleBadge();
          }
        }
        await ipadPrintsApi.update(data.id, { printedAt: new Date() });
      } catch (error) {
        ipadLogsApi.insert({ type: '[socketPrint]Printer error', message: error?.message || '' });
        logger(error);
      }
    },
    [ipad, printSampleBadge, printVisitBadge]
  );

  useEffect(() => {
    const getPrinterData = async () => {
      await getPrinter(ipad?.printerId);
    };

    feathersApp?.service('ipad-prints').on('created', socketPrint);
    feathersApp?.service('printers').on('patched', getPrinterData);

    return () => {
      feathersApp?.service('ipad-prints').off('created', socketPrint);
      feathersApp?.service('printers').off('patched', getPrinterData);
    };
  }, [feathersApp, ipad, printer, socketPrint, getPrinter]);

  return (
    <PrintersContext.Provider
      value={{
        printVisitBadge,
        printSampleBadge,
        checkPrinter,
        printer,
        printerConnected,
      }}
    >
      <Badge badge={currentBadge} onPrint={handlePrintBadge} />
      {children}
    </PrintersContext.Provider>
  );
};

export function usePrinters(): PrintersContextData {
  const context = useContext(PrintersContext);

  if (!context) {
    throw new Error('usePrinters must be used within an PrintersProvider');
  }

  return context;
}
